import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'


const FormProducto = () => {
    const [marcas, setMarcas] = useState([])
    const [marca, setMarca] = useState(0)
    const [subRubros, setSubRubros] = useState([])
    const [subRubro, setSubRubro] = useState()
    const { setValProducto } = useContext(ContextStates)

    useEffect(() => {
        const consultaMarcas = async () => {
            const url = Url + 'api/listadomarcas.php';
            const resultado = await axios.get(url);
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id)
        }
        consultaMarcas()
    }, [])

    useEffect(() => {
        const consultaSubRubros = async () => {
            const url = Url + 'api/listSubRubros.php';
            const resultado = await axios.get(url);
            setSubRubros(resultado.data.results);
            setSubRubro(resultado.data.results[0].id)
        }
        consultaSubRubros()
    }, [])


    const formik = useFormik({
        initialValues: {
            descripcion: '',
            descripcionExt: ''
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("La descripcion es obligatoria"),
            descripcionExt: Yup.string(),
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('descripcion', valores.descripcion);
            formData.append('descripcionExt', valores.descripcionExt);
            formData.append('idmarca', marca);
            formData.append('idsubrubro', subRubro);
            try {
                const results = await axios({
                    url: Url + 'api/altaProductos.php',
                    method: 'POST',
                    data: formData,
                })
                setValProducto(results.data.results.id);
                resetForm()
            } catch (e) {
                console.log(e)
            }
        }
    });


    return (

        <form
            className="w-full mt-10 py-2 md:py-5 px-3 sm:px-20 bg-white rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Agregar Productos</h2>
                <div className="sm:items-start">
                    <label htmlFor="sMarca" className="w-full block mt-5 text-black uppercase font-bold">Sub Rubro</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100'
                        id='sSubRubro'
                        onChange={(e) => setSubRubro(e.target.value)}
                    >
                        {subRubros && (
                            subRubros.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        )}
                    </select>

                    <label htmlFor="sMarca" className="w-full block mt-5 text-black uppercase font-bold">Marca</label>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100'
                        id='sMarca'
                        onChange={(e) => setMarca(e.target.value)}
                    >
                        {marcas && (
                            marcas.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        )}
                    </select>

                    <label htmlFor="descripcion" className="block mt-5 text-black uppercase font-bold">Descripción</label>
                    <input
                        className="py-2 px-3 w-full rounded"
                        id="descripcion"
                        placeholder="Descripción"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcionExt" className="block text-black uppercase font-bold">Detalles</label>
                    <input
                        className="py-2 px-3 w-full rounded"
                        id="descripcionExt"
                        placeholder="Ingrese una descripción detallada"
                        type="text"
                        value={formik.values.descripcionExt}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.descripcionExt && formik.errors.descripcionExt ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcionExt}</p>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="mt-auto">
                <div className="w-full rounded-lg px-3">
                    <input
                        type="submit"
                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        value="Guardar Datos"
                    />
                </div>
            </div>

        </form>
    )
}

export default FormProducto