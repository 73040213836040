import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ContextStates from '../../context/ContextStates';
import { useMercadopago } from 'react-sdk-mercadopago';
import { Url } from '../Url';


const FormMP = () => {
    const [ preferenceId, setPreferenceId ] = useState(null);
    const { ordenes, ordenesPrev } = useContext(ContextStates)
    const [preferencia,setPreferencia] = useState(false);
    const [rendered, setRendered] = useState(false);
  
    
    const mp = useMercadopago.v2('TEST-56db20ae-073e-4cb5-86a9-f6f586fdb75c', {
        locale: 'es-AR'
    });

    const mail = localStorage.getItem('jjn_em');

    const addCheckout = () => {
        // Inicializa el checkout Aca seria la linea 20
        if (mp && !rendered) {
          mp.checkout({ 
              preference: {
                  id: preferenceId,
              },
              render: {
                  container: '.cho-container', // Indica el nombre de la clase donde se mostrará el botón de pago
                  label: 'Realizar Pago', // Cambia el texto del botón de pago (opcional)
              },
          });
          setRendered(true);
        }
    }

    useEffect(() => {
        // luego de montarse el componente, le pedimos al backend el preferenceId
        const generarPago = async () => {
            const formData=new FormData()
            formData.append('mail',mail);
            if (ordenes.length>0)
            {
                formData.append('orders',ordenes);
            }
            else
            {
                formData.append('orders',ordenesPrev);
            }
            if (!preferencia)
            {
                setPreferencia(true);
                try{
                    const results = await axios({
                        url: Url+'api/pago.php',
                        method: 'POST',
                        data: formData,
                    })
                    console.log(results)     
                    setPreferenceId(results.data.results.preferenceId);
                    localStorage.setItem('jjn_idp',results.data.results.idpedido)
                } catch (e) {
                    console.log(e)
                }    
            }
        }
        if (ordenes.length>0 || ordenesPrev.length>0)
            generarPago()
    }, [])
    
    useEffect(() => {
        if (preferenceId) {
            // con el preferenceId en mano, inyectamos el script de mercadoPago
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://sdk.mercadopago.com/js/v2';
            script.addEventListener('load', addCheckout); // Cuando cargue el script, se ejecutará la función addCheckout
            let contenedor=document.getElementById('contenedorPago');
            contenedor.appendChild(script);
        }
    }, [preferenceId]);

    return (
        <>
            {
                preferenceId ? (
                    <div className='md:w-1/6 w-1/3 mx-auto mt-5'>
                        <div>
                            <div id='contenedorPago' className="cho-container"></div>
                        </div>
                    </div>
                ) : (
                    <div className='sm:w-1/6 w-1/12 mx-auto'>
                        <div className="bg-indigo-500 p-3 rounded-full flex">
                            <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                            </svg>
                            <p className='ml-3 text-white'>Procesando...</p>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default FormMP