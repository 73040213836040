import { useContext } from 'react';
import ContextStates from '../context/ContextStates';
import Layout from '../components/Layout';
import ListPedidos from '../components/ListPedidos';


const Pedidos = () => {

	return (
		<Layout
			pagina={"Mi compra"}
		>
			<div className='w-full pt-20 pb-20'>
                <ListPedidos/>
			</div>
		</Layout>
	)
}

export default Pedidos