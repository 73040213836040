import { useState, useEffect, useContext } from 'react';
import Layout from '../components/Layout';
import ContextStates from '../context/ContextStates';
import { FaTrash } from "react-icons/fa"
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animations'
import { Link } from 'react-router-dom';
import { Url } from '../components/Url';


const Cart = () => {
	const { setViewOpen, setSubModelo, setFormView, ordenes } = useContext(ContextStates);
	const [ total, setTotal ] = useState(0)


	useEffect(()=>{
		let rData=[...ordenes];
		let auxTotal=0;
		rData.forEach(element => {
			auxTotal=auxTotal+(parseInt(element.cantidad)*parseInt(element.precio))
		});

		setTotal(auxTotal)
	},[ordenes])

	const handleQuit = (item) => {
		setSubModelo({
			id:item.id,
			marca:item.marca,
			descripcion:item.descripcion,
			imagen:item.imagen,
			precio:item.precio,
			stock:item.stock
		});
		setFormView(2);
		setViewOpen(true);
	}


	return (
		<Layout
			pagina={"Mi compra"}
		>
			<div className='w-full pt-20 pb-20'>
				<div className='md:w-9/12 w-full mx-auto shadow-xl rounded-xl'>
					{ ordenes.map((item,index)=>(
						
						<div className="sm:w-2/3 w-full mx-auto flex" key={index}>
							<div className='w-1/2'>
								<motion.img className="" src={Url+item.imagen}
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{once:true, amount: 0.1}}
									variants={areaAnimateLR}
								/>
							</div>
							<div className='w-1/2 pt-20 flex'>
								<motion.div className='w-1/2 p-2'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{once:true, amount: 0.1}}
									variants={areaAnimateRL}
								>
									<h3 className="text-center text-2xl font-semibold uppercase">{item.marca}</h3>
									<p className="text-center text-xl">{item.descripcionproducto}</p>
									<p className="text-center text-lg">{item.descripcion}</p>
									<p className="mt-5 text-center text-lg font-semibold">Precio Unitario: $ {item.precio}</p>
									<p className="mt-5 text-center text-lg font-semibold">Cantidad: {item.cantidad}</p>
								</motion.div>
								<motion.div className='w-1/2 p-2'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{once:true, amount: 0.1}}
									variants={areaAnimateRL}
								>
									<p className="mt-5 text-center text-lg font-semibold">Subtotal: $ {parseInt(item.cantidad)*parseInt(item.precio)}</p>
									<div className='w-full mt-10 text-center'>
										<button
											type='button'
											className='bg-red-500 p-3 rounded-xl'
											onClick={()=>handleQuit(item)}
										>
											<FaTrash className='text-center mx-auto text-2xl text-white'/>
										</button>
									</div>
								</motion.div>
							</div>
						</div>
					))}
					{ ordenes.length!==0 ? (
					<>
						<div className="sm:w-2/3 w-full mx-auto flex bg-slate-100 p-20 rounded-xl">
							<div className='w-full mx-auto'>
								<p className='font-bold uppercase text-2xl text-right'>Total: $ {total}</p>
							</div>
						</div>
						<div className='md:w-1/4 w-11/12 py-10 mx-auto'>
							<Link to={"/resumen"}>
								<button
									type='button'
									className='bg-blue-800 w-full p-3 rounded-xl text-white uppercase'
									
								>
									Confirmar y continuar con la compra
								</button>
							</Link>
						</div>
					</>
					):(
					<>
						<div className="sm:w-2/3 w-full mx-auto flex p-20 rounded-xl">
							<div className='w-full mx-auto'>
								<p className='font-bold text-2xl text-center'>Su carrito de compras se encuentra vacío</p>
							</div>
						</div>
						<div className='md:w-1/4 w-11/12 py-10 mx-auto'>
							<Link to={"/"}>
								<button
									type='button'
									className='bg-blue-800 w-full p-3 rounded-xl text-white uppercase'
									
								>
									Volver a inicio
								</button>
							</Link>
						</div>
					</>					
					) }
				</div>
			</div>
		</Layout>
	)
}

export default Cart