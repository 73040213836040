import React, { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormQuit = () => {
    const { subModelo, setViewOpen, ordenes, setOrdenes, mail } = useContext(ContextStates)


    const handleQuit = async() => {
        if (mail)
        {
            const formData=new FormData()
            formData.append('mail',mail);
            formData.append('idmodelo',subModelo.id);
            try{
                const results = await axios({
                    url: Url+'api/quitProducto.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
            } catch (e) {
                console.log(e)
            }
        }
        let rData=[...ordenes];
        let aux=[];
        rData.forEach(element => {
            if (subModelo.id!==element.id)
            {   
                aux.push(element);
            }                
        });
        setOrdenes(aux)
        setViewOpen(false)
    }


    return (
        <div className='p-10 w-10/12 mx-auto'>
            <img className="mx-auto" src={Url+subModelo.imagen}/>
            <h3 className="text-center text-2xl font-semibold uppercase">{subModelo.marca}</h3>
            <p className="text-center text-xl">{subModelo.descripcionproducto}</p>
            <p className="text-center text-lg">{subModelo.descripcion}</p>
            <button 
                type='button' 
                className='mt-5 w-full bg-red-500 text-xl uppercase text-white p-4 rounded-xl' 
                onClick={()=>handleQuit()}
            >Quitar Producto</button>
        </div>               
    )
}

export default FormQuit