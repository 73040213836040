import React, { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Url } from '../Url'

const FormMPS = () => {
    const [ productos, setProductos ] = useState([]);
    const [ pedido, setPedido ] = useState({});
    const { setOrdenes, setPendientes } = useContext(ContextStates);
    const mail = localStorage.getItem('jjn_em');
    const idPedido = localStorage.getItem('jjn_idp');

    const finishOrden = () => {
        setOrdenes([]);
        localStorage.removeItem('localOrder');
        setPendientes(false);
    }

    useEffect(() => {
        const checkCompra = async () => {
            console.log("idpedido: "+idPedido)
            const formData=new FormData()
            formData.append('mail',mail)
            formData.append('idpedido',idPedido)
            try{
                const response = await axios({
                    url: Url+'api/detallecompra.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setProductos(response.data.results.productos)
                setPedido({
                    'numeropedido':response.data.results.numeropedido,
                    'numerorecibo':response.data.results.numerorecibo,
                    'total':response.data.results.total
                })
                finishOrden()
            } catch (e) {
                console.log(e)
            }
        }
        checkCompra()
    },[idPedido])

  return (
    <div className='w-10/12 mx-auto'>
        <h1 className='w-3/4 mx-auto text-center p-3 rounded-sm bg-green-300 shadow-md'>El pago se realizó de manera correcta</h1>
        <div className='w-3/4 mt-2 mx-auto p-5 justify-center bg-slate-50 rounded-3xl'>
            <p className='text-xl text-center'> Detalles del pedido Nº {pedido.numeropedido}</p>
            <p className='text-lg mt-2'>Recibo Nº {pedido.numerorecibo}</p>
            <p className='text-lg mt-2'>Fecha de Pedido: {pedido.fechapedido}</p>
            <p className='text-lg mt-2'>Total: ${pedido.total}</p>
            <table className='w-full mx-auto mt-10 bg-white border border-gray-300'>
                <tr><th>Cantidad</th><th>Producto</th><th>Subtotal</th></tr>
            {
                productos.map(item => (
                    <tr className='border border-gray-300'>
                        <td className='text-center'>{item.cantidad}</td>
                        <td className='text-center'>{item.descripcion}</td>
                        <td className='text-center'>${item.subtotal}</td>
                    </tr>
                ))
            }
            </table>
        </div>
        <div className='mt-5 w-3/4 sm:w-1/4 mx-auto'>
        <Link to={'/'}>
            <p 
                className='w-full p-3 bg-blue-500 text-xl text-white text-center uppercase rounded-xl shadow-md'>
                Salir
            </p>
        </Link>
        </div>
    </div>               
  )
}

export default FormMPS