import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import authContext from '../../context/auth/authContext';
import ContextStates from '../../context/ContextStates';
import SideBar from './SideBar';
import Login from '../../pages/admin/Login';

const Layout = ({children, pagina}) => {
	const { setMail, setViewSearch } = useContext(ContextStates);
	const AuthContext = useContext( authContext);
	const { autenticado, usuarioAutenticado } = AuthContext;
	const tipo=localStorage.getItem('jjn_tip');
	
	useEffect(() => {
		setViewSearch(false)
		usuarioAutenticado()
		if (localStorage.getItem('jjn_em'))
		{
			setMail(localStorage.getItem('jjn_em'));
		}
		else
			setMail(null)
	}, [autenticado]);

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<title>Gabi Rotondo {pagina}</title>
				<meta name="description" content="Diseño de ropa" />
				<meta property="og:title" content="Gabi Rotondo" />
				<meta property="og:description" content="Diseño de ropa" />        
				<meta property="og:url" content="https://gabirotondo.com.ar" />
				<meta property="og:site_name" content="Gabi Rotondo" />
				<meta property="og:locale" content="es_AR" />
				<meta property="og:type" content="article" />
			</Helmet>
			<div>
				{
					tipo==='1' ? (
						<>
							<div className='flex'>
								<SideBar/>
								{children}
							</div>
							
						</>
					) : (
						<Login/>
					)
			}
			</div>
		</HelmetProvider>
	)
}

export default Layout