import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const ListadoArticulos = () => {
    const [compras, setCompras] = useState([])
    const [tipo, setTipo] = useState(0)
    const { setSubModeloSelected, idArticulo, setFormView, setViewOpen } = useContext(ContextStates)

    useEffect(() => {
        const consultaProductos = async () => {
            const formData = new FormData()
            formData.append('tipo', tipo)
            try {
                const response = await axios({
                    url: Url + 'api/listArticulos.php',
                    method: 'POST',
                    data: formData,
                })
                setCompras(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultaProductos()
    }, [idArticulo])

    const handleView = (item, action) => {
        setSubModeloSelected({
            "id": item.id,
            "descripcion": item.descripcion,
            "precio": item.precio,
            "stock": item.stock,
            "stockmin": item.stockmin,
            "estado": item.estado,
            "idmarca": item.idmarca,
            "idproducto": item.idproducto,
            "idmodelo": item.idmodelo,
            "pausado": item.pausado
        })
        setFormView(action)
        setViewOpen(true)
    }


    return (
        <div className="w-full py-5 px-2 md:px20 rounded-2xl">
            <h2 className='text-center text-gray-700 text-sm md:text-xl'>Articulos</h2>
            <div className="sm:items-start">
                <table className='w-full'>
                    <tr className='p-1 bg-blue-400 text-white'>
                        <th className='px-2'>Descripción</th>
                        <th className='px-2'>Modelo</th>
                        <th className='px-2'>Producto</th>
                        <th className='px-2'>Marca</th>
                        <th className='px-2'>Precio</th>
                        <th className='px-2'>Stock</th>
                        <th className='px-2'>Stock Min.</th>
                        <th className='px-2'>Estado</th>
                        <th className='px-2'>Acciones</th>
                    </tr>
                    {compras && (
                        compras.map((item, index) => (
                            <tr key={index} className={`border border-gray-300 p-2 ${item.pago === '200' && 'bg-red-100'}`}>
                                <td className='text-center'>{item.descripcion}</td>
                                <td className='text-center'>{item.modelo}</td>
                                <td className='text-center'>{item.descripcionproducto}</td>
                                <td className='text-center'>{item.marca}</td>
                                <td className='text-center'>{item.precio}</td>
                                <td className='text-center'>{item.stock}</td>
                                <td className='text-center'>{item.stockmin}</td>
                                <td className={`text-center ${item.pausado === '1' && 'bg-red-400 text-white'}`}>{`${item.pausado === '1' ? 'Pausado' : 'Activo'}`}</td>
                                <td className='text-center'>
                                    <button className='bg-gray-400 ml-2 hover:bg-gray-300 rounded py-1 px-2 text-white' onClick={() => handleView(item, 8)}>Editar</button>
                                    <button className='bg-red-600 ml-2 hover:bg-red-400 rounded py-1 px-2 text-white' onClick={() => handleView(item, 9)}>Eliminar</button>
                                </td>
                            </tr>
                        ))
                    )}
                </table>
            </div>
        </div>
    )
}

export default ListadoArticulos