import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FDelSubModelo = () => {
    const { subModeloSelected } = useContext(ContextStates)
    

    const handleDelete = async() => {
        const formData=new FormData()
        formData.append('id',subModeloSelected.id);
        try{
            const results = await axios({
                url: Url+'api/delArticulo.php',
                method: 'POST',
                data: formData,
            })
            
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="w-5/6 mx-auto py-5 px-20 rounded-2xl bg-slate-50">
            <h2 className='text-center text-gray-700 text-xl'>Eliminar Artículo</h2>
                <div className="p-5">
                    <h2>{subModeloSelected.marca}</h2>
                    <p>{subModeloSelected.producto}</p>
                    <p>{subModeloSelected.modelo}</p>
                    <p>{subModeloSelected.descripcion}</p>
                </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className="w-full shadow-md p-3 bg-red-500 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        onClick={()=>handleDelete()}
                    >Eliminar</button>
                </div>
            </div>
        </div>
)
}

export default FDelSubModelo