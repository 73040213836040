import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const ListadoPedidos = () => {
    const [ compras, setCompras ] = useState([])
    const [ tipo, setTipo ] = useState(0)
    const { setPedidoSelected, valPedido, setFormView, setViewOpen } = useContext(ContextStates)

    useEffect(()=>{
        const consultaProductos = async () => {
            const formData=new FormData()
            formData.append('tipo',tipo)
            try{
                const response = await axios({
                    url: Url+'api/listCompras.php',
                    method: 'POST',
                    data: formData,
            })
            setCompras(response.data.results)
            } catch (e) {
                console.log(e)
            }    
        }
        consultaProductos()
    },[tipo, valPedido])

    const handleView = (id) => {
        console.log("pedido",id)
        setPedidoSelected(id)
        setFormView(7)
        setViewOpen(true)
    }


    return (
        <div className="w-full py-5 px-2 md:px-20 rounded-2xl">
            <h2 className='text-center text-gray-700 text-xl'>Compras</h2>
            <div className="sm:items-start">
                <table className='w-full'>
                    <tr className='p-1 bg-blue-400 text-white'><th>Nº de Pedido</th><th>Total</th><th>Fecha</th><th>Hora</th><th>Estado</th><th>Acciones</th></tr>
                    { compras && (
                        compras.map((item, index) => (
                            <tr key={index} className={`border border-gray-300 p-2 ${item.pago==='200' && 'bg-red-100'}`}>
                                <td className='text-center'>{item.numeropedido}</td>
                                <td className='text-center'>{item.total}</td>
                                <td className='text-center'>{item.fechapedido}</td>
                                <td className='text-center'>{item.horapedido}</td>
                                <td className='text-center'>{item.pago==='200' ? 'Pendiente Aprobación':item.pago==='20' ? 'Inicio Transf.': item.pago==='1' ? 'Pago Aprobado': item.pago}</td>
                                <td className='text-center'><button className='bg-blue-600 hover:bg-blue-400 rounded-lg py-1 px-3 text-white' onClick={()=>handleView(item.idpedido)}>Ver</button></td>
                            </tr>
                            ))
                    ) }
                </table>
            </div>
        </div>
    )
}

export default ListadoPedidos