import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./Header"
import Footer from "./Footer"
import authContext from '../context/auth/authContext';
import ContextStates from '../context/ContextStates';
import { useDispatch } from 'react-redux'
import SearchBar from './SearchBar';

const Layout = ({children, pagina}) => {
	const { setMail, setViewSearch } = useContext(ContextStates);
	const AuthContext = useContext( authContext);
	const { autenticado, usuarioAutenticado } = AuthContext;

	useEffect(() => {
		setViewSearch(false)
		usuarioAutenticado()
		if (localStorage.getItem('jjn_em'))
			setMail(localStorage.getItem('jjn_em'));
		else
			setMail(null)
	}, [autenticado]);

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<title>Gabi Rotondo {pagina}</title>
				<meta name="description" content="Diseño, moda, vestidos, indumentaria" />
				<meta property="og:title" content="Gabi Rotondo" />        
				<meta property="og:description" content="Diseño, moda, vestidos, indumentaria" />        
				<meta property="og:image" content="https://gabirotondo.com.ar/api/img/logos/logo.png" />
				<meta property="og:url" content="https://gabirotondo.com.ar" />
				<meta property="og:site_name" content="Gabi Rotondo" />
				<meta property="og:locale" content="es_AR" />
				<meta property="og:type" content="article" />
			</Helmet>
			<div>
				<Header />
				<SearchBar/>
				{children}
				<Footer />
			</div>
		</HelmetProvider>
	)
}

export default Layout