import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormPerfil = () => {
    const [password, setPassword] = useState('')
    const [mensaje, setMensaje] = useState('')
    const [resultado, setResultado] = useState(0)
    const [newPassword, setNewPassword] = useState('')
    const [rPassword, setRPassword] = useState('')
    const [edit, setEdit] = useState(false)
    const [habilitado, setHabilitado] = useState(false)
    const { mail } = useContext(ContextStates)


    useEffect(() => {
        if ((newPassword === rPassword) && newPassword !== '' && password !== '')
            setHabilitado(true)
        else
            setHabilitado(false)
    }, [password, newPassword, rPassword])

    useEffect(() => {
        const showMessage = () => {
            if (mensaje !== "") {
                setTimeout(() => {
                    setMensaje("");
                    if (resultado === 1) {
                        setPassword("")
                        setNewPassword("")
                        setRPassword("")
                        setEdit(false)
                    }
                }, 3000)
            }
        }
        showMessage()
    }, [mensaje])

    const setCuenta = async () => {
        if (habilitado) {
            const formData = new FormData()
            formData.append('mail', mail);
            formData.append('password', password);
            formData.append('newPassword', newPassword);
            formData.append('rPassword', rPassword);
            try {
                const results = await axios({
                    url: Url + 'api/setPassword.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)
                if (results.data.results.modificado === 0) {
                    setMensaje("La contraseña actual es incorrecta");
                    setResultado(0)
                }
                else if (results.data.results.modificado === 1) {
                    setMensaje("La contraseña se actualizó de manera correcta");
                    setResultado(1)
                }

                //setEdit(false)
            } catch (e) {
                console.log(e)
            }
        }
    }


    return (
        <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-xl'>Perfil de Usuario</h2>
            <div className='w-full md:w-1/2 mx-auto border-2xl p-3 bg-slate-100 mb-10 rounded-xl'>
                {edit ? (
                    <>
                        <label htmlFor='password' className='uppercase text-md text-black'>Contraseña Actual</label>
                        <input type='password' id='password' value={password} placeholder='Ingrese la Contraseña Actual' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setPassword(e.target.value)} />
                        <label htmlFor='newPassword' className='uppercase text-md text-black'>Nueva Contraseña</label>
                        <input type='password' id='newPassword' value={newPassword} placeholder='Ingrese la Nueva Contraseña' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setNewPassword(e.target.value)} />
                        <label htmlFor='rpassword' className='uppercase text-md text-black'>Repetir Contraseña</label>
                        <input type='password' id='rpassword' value={rPassword} placeholder='Repita la Nueva Contraseña' className='mb-3 w-full text-sm p-2 rounded' onChange={(e) => setRPassword(e.target.value)} />
                        <div className='h-20'>
                            {
                                mensaje !== '' &&

                                <p className={`p-3 w-full text-center text-gray-700 rounded-lg ${resultado === 0 ? 'bg-orange-200' : 'bg-green-200'}`}>{mensaje}</p>

                            }
                        </div>
                        <button
                            className={`text-white p-3 rounded text-center ${habilitado ? 'bg-blue-500' : 'bg-gray-300'}`}
                            onClick={() => setCuenta()}
                        >Cambiar Contraseña
                        </button>
                        <button
                            className='ml-5 bg-red-500 text-white p-3 rounded text-center'
                            onClick={() => setEdit(false)}
                        >Cancelar
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            type='button'
                            className='bg-orange-500 text-white p-3 rounded text-center'
                            onClick={() => setEdit(true)}
                        >
                            Cambiar Contraseña
                        </button>
                    </>
                )}
            </div>
        </div>
    )
}

export default FormPerfil