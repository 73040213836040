import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const ViewVentas = () => {
    const [ ventas, setVentas ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ fechaInicio, setFechaInicio ] = useState('')
    const [ fechaFin, setFechaFin ] = useState('')
    const { setPedidoSelected,setFormView, setViewOpen } = useContext(ContextStates)

    useEffect(()=>{
        const calcTotal = () => {
            let t=0;
            ventas.forEach(element => {
                t=t+parseInt(element.total)
            });
            setTotal(t)
        }
        calcTotal()
    },[ventas])

    useEffect(()=>{
        const consultaVentas = async () => {
            const formData=new FormData()
            formData.append('fechainicio',fechaInicio)
            formData.append('fechafin',fechaFin)
            try{
                const response = await axios({
                    url: Url+'api/listadoVentas.php',
                    method: 'POST',
                    data: formData,
            })
            console.log(response)
            setVentas(response.data.results)
            } catch (e) {
                console.log(e)
            }    
        }
        consultaVentas()
    },[fechaInicio, fechaFin])

    const handleView = (id) => {
        setPedidoSelected(id)
        setFormView(7)
        setViewOpen(true)
    }

    return (
        <div className="w-full py-5 px-2 md:px-20 rounded-2xl">
            <h2 className='text-center text-gray-700 text-xl'>Ventas</h2>
            <div className="w-full md:w-3/4 mx-auto flex mt-5 mb-5">
                <div className='w-1/2 sm:w-1/4 bg-slate-200 p-3 rounded-xl'>
                    <label htmlFor='fechainicio'>Fecha de Inicio</label>
                    <input type='date' className='w-full p-3 rounded-lg' id='fechainicio' placeholder='Fecha de Inicio' value={fechaInicio} onChange={(e)=>setFechaInicio(e.target.value)}/>
                </div>
                <div className='w-1/2 sm:w-1/4 bg-slate-200 p-3 ml-5 rounded-xl'>
                    <label htmlFor='fechafin'>Fecha de Fin</label>
                    <input type='date' className='w-full p-3 rounded-lg' id='fechafin' placeholder='Fecha de Fin' value={fechaFin} onChange={(e)=>setFechaFin(e.target.value)}/>
                </div>
            </div>
            { ventas && (
                <table className='w-full md:w-3/4 mx-auto'>
                    <tr className='p-1 bg-blue-400 text-white text-center'><th>Fecha</th><th>Nº</th><th>Hora</th><th>Total</th><th>Acciones</th></tr>
                    {
                        ventas.map(item => (
                            <tr className='border border-gray-300 p-2 text-center'>
                                <td>{item.fechapedido}</td>
                                <td>{item.numeropedido}</td>
                                <td>{item.horapedido}</td>
                                <td>${item.total}</td>
                                <td className='text-center'><button className='bg-blue-600 hover:bg-blue-400 rounded-lg py-1 px-3 text-white' onClick={()=>handleView(item.idpedido)}>Ver</button></td>
                            </tr>
                        ))                        
                    }
                    <tr className='border border-gray-300 p-2 text-center font-bold bg-slate-50'>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td>${total}</td>
                        <td></td>
                    </tr>
                </table>
            )}
            
        </div>
    )
}

export default ViewVentas