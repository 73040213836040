import React, { Fragment, useContext, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BiCartAlt } from 'react-icons/bi'
import { FiMenu } from 'react-icons/fi'
import { IoMdClose } from 'react-icons/io'
import { FaInstagram,FaFacebook,FaWhatsapp, FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import authContext from '../context/auth/authContext';
import ContextStates from '../context/ContextStates';
import Logo from '../assets/Logo.png'
import axios from 'axios';
import { Url } from './Url';



const navigation = [
  { name: 'Inicio', href: '/', current: false },
  { name: 'Promociones', href: '/promociones', current: false },

]


export const NavBar = () => {
    const { usuarioAutenticado, cerrarSesion } = useContext( authContext);
    const mail = localStorage.getItem('jjn_em');
    const { ordenes, setFormView, setViewOpen, viewSearch, setViewSearch, pendientes, contactos, setContactos } = useContext(ContextStates)

    useEffect(() => {
        usuarioAutenticado()
      }, []);

      
    useEffect(() => {
        const query = async () => {
            const url = `${Url}api/getDatosContacto.php`;
            const resultado = await axios.get(url);
            setContactos(resultado.data.results);
            console.log(resultado.data.results)
          }
          query();
    }, [])

    return (
        <Disclosure as="nav" className="shadow-xl  bg-slate-50">
        {({ open }) => (
            <>
            <div className="container mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-24">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button*/}
                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="sr-only">Abrir Menú</span>
                            {open ? (
                                <IoMdClose className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <FiMenu className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </Disclosure.Button>
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                            <Link to="/">
                                <img
                                    className="block lg:hidden h-12 w-auto"
                                    src={Logo}
                                    alt="J y J Neumáticos"
                                />
                                <img
                                    className="hidden lg:block h-12 w-auto"
                                    src={Logo}
                                    alt="J y J Neumáticos"
                                />
                            </Link>
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4 mt-2">
                                {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={` px-3 py-2 rounded-md text-sm font-medium ${item.current ? 'bg-slate-200 text-gray-600' : 'text-gray-600 hover:bg-slate-200 '}`}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                                
                                ))}

                            </div>
                        </div>
                        <div className="sm:ml-6">
                            <button
                                className={`px-3 py-2 rounded-md mt-2 text-sm font-medium ${viewSearch ? 'bg-slate-200 text-gray-600' : 'text-gray-600 hover:bg-slate-200 '}`}
                                onClick={()=>setViewSearch(!viewSearch)}
                            >
                                <FaSearch/>
                            </button>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        { typeof contactos[0]!=='undefined' && (
                        <>
                        <a 
                            className="hidden sm:block hover:bg-slate-200 p-2 rounded-full text-gray-600 focus:outline-none" 
                            href={contactos[2].contacto}
                            target="_blank"
                            rel='noreferrer'
                        >
                            <FaFacebook className="h-6 w-6" aria-hidden="true" />
                        </a>
                        <a
                            className="hidden sm:block hover:bg-slate-200 p-2 rounded-full text-gray-600 focus:outline-none"
                            href={contactos[3].contacto}
                            target="_blank"
                            rel='noreferrer'
                        >
                            <FaInstagram className="h-6 w-6" aria-hidden="true" />
                        </a>
                        <a
                            className="hidden sm:block hover:bg-slate-200 p-2 rounded-full text-gray-600 focus:outline-none"
                            href={`https://wa.me/${contactos[0].contacto}`} 
                            target="_blank"
                            rel='noreferrer'
                        >
                            <FaWhatsapp className="h-6 w-6" aria-hidden="true" />
                        </a>
                        </>
                        )}
                        <Link 
                            className='ml-6 mr-3 hidden sm:block hover:bg-slate-200 p-2 rounded-full text-gray-600 focus:outline-none'
                            to={"/cart"}
                        >
                            {
                                ordenes.length>0 && (
                                    <p className='bg-red-600 text-gray-600 font-bold px-2 ml-5 rounded-full absolute'>{ordenes.length}</p>
                                )
                            }
                            <BiCartAlt className='h-6 w-6'/>
                        </Link>
                        {
                            mail ? (
                                <Menu as="div" className="hidden sm:inline-block relative text-left z-10">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-600">
                                            {mail}
                                            {
                                                pendientes && (
                                                    <p className='bg-red-600 text-white font-bold px-2 rounded-full'>1</p>
                                                )
                                            }
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="px-1 py-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                            <Link
                                                className={`${active ? 'bg-blue-500 text-gray-600' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                to={"/cart"}
                                            >
                                                Mi carrito
                                            </Link>
                                            )}
                                        </Menu.Item>
                                        </div>
                                        <div className="px-1 py-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                            <Link
                                                className={`${active ? 'bg-blue-500 text-gray-600' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                to={"/pedidos"}
                                            >
                                                Mis pedidos
                                                {
                                                    pendientes && (
                                                        <p className='bg-red-600 text-white font-bold px-2 rounded-full ml-1'>1</p>
                                                    )
                                                }
                                            </Link>
                                            )}
                                        </Menu.Item>
                                        </div>
                                        <div className="px-1 py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                            <button
                                                className={`${active ? 'bg-blue-500 text-gray-600' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                onClick={() => cerrarSesion ()}
                                            >
                                                Cerrar Sesión
                                            </button>
                                            )}
                                        </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                    </Transition>
                                </Menu>                            
                            ) : (
                                <div className='hidden sm:block ml-3'>
                                    <button className='border text-gray-600 p-1 rounded-2xl ml-3 uppercase'
                                        onClick={() => {
                                            setFormView(3);
		                                    setViewOpen(true);
                                        }}
                                    >
                                        Iniciar Sesión
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            
{/* Panel para mobiles */}
            <Disclosure.Panel className="sm:hidden z-50 absolute w-full bg-slate-50/95">
                <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                        <Link
                            key={item.name}
                            to={item.href}
                            className={`block px-3 py-2 rounded-md text-base font-medium ${item.current ? 'bg-slate-200 text-gray-600' : 'text-gray-600 hover:bg-blue-500'}`}
                            aria-current={item.current ? 'page' : undefined}
                        >
                            {item.name}
                        </Link>
                    ))}

                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                    <Link 
                        className={'block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:bg-blue-500'}
                        to={"/cart"}
                    >
                            {
                                ordenes.length>0 && (
                                    <p className='bg-red-600 text-white font-bold px-2 ml-5 rounded-full absolute'>{ordenes.length}</p>
                                )
                            }
                        <BiCartAlt className='h-6 w-6'/>
                    </Link>
                </div>
                {
                    mail && (
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <Link 
                            className={'block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:bg-blue-500'}
                            to={"/pedidos"}
                        >
                            Mis pedidos
                            {
                                pendientes && (
                                    <p className='bg-red-600 text-white font-bold px-2 ml-1 rounded-full'>1</p>
                                )
                            }
                        </Link>
                    </div>    
                    )
                }
                <div className='px-3 pt-2 mb-5 flex'>
                    <a 
                        className="hover:bg-blue-500 p-2 rounded-full text-gray-600 focus:outline-none" 
                        href='https://www.facebook.com/lideraturismo'
                        target={"_blank"}
                        rel='noreferrer'
                    >
                        <FaFacebook className="h-6 w-6" aria-hidden="true" />
                    </a>
                    <a
                        className="hover:bg-blue-500 p-2 rounded-full text-gray-600 focus:outline-none"
                        href='https://www.instagram.com/lidera2.0/'
                        target={"_blank"}
                        rel='noreferrer'
                    >
                        <FaInstagram className="h-6 w-6" aria-hidden="true" />
                    </a>
                    <a
                        className="hover:bg-blue-500 p-2 rounded-full text-gray-600 focus:outline-none"
                        href='https://wa.me/543816001149'
                        target={"_blank"}
                        rel='noreferrer'
                    >
                        <FaWhatsapp className="h-6 w-6" aria-hidden="true" />
                    </a>
                </div>
                {
                    mail ? (
                        <div className='m-5 mb-2'>
                            <p className='text-gray-600 text-center mb-2'>{mail}</p>
                            <button className='bg-white text-blue-600 text-center p-1 mb-2 rounded-xl w-full' onClick={() => cerrarSesion ()}>Cerrar Sesión</button>
                        </div>
                        ) : (
                        <div className='m-5 mb-2 border-2 border-white p-1 text-center rounded-2xl'>
                            <Link 
                                className='text-gray-600 text-center mb-2 font-bold'
                                to="/login"
                            >
                                Iniciar Sesión
                            </Link>
                        </div>    
                        )
                }
            </Disclosure.Panel>
            </>
        )}
        </Disclosure>
    )
}


