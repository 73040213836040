import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';
import FormMPS from '../components/forms/FormMPS';


const Results = () => {

    let result=useParams()

	return (
		<Layout
			pagina={"Mi compra"}
		>
			<div className='w-full pt-20 pb-20'>
                {
                    result.status==='success' ? (
                        <p><FormMPS/></p>

                    ) : result.status==='pending' ? (
                        <p>Pendiente</p>

                    ) : (
                        <p>Ocurrió un error</p>
                    )
                }
			</div>
		</Layout>
	)
}

export default Results