import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css/bundle';
import { Url } from './Url';


const SliderPrincipal = () => {
    const [ sliders, setSliders ] = useState([]);

    useEffect(() => {
        const query = async () => {
            const url = `${Url}api/slideprincipal.php`;
            const resultado = await axios.get(url);
            setSliders(resultado.data.results);
          }    
          query();
    }, [])
    
  

    let slides = [];

    sliders.slice(0,5).map(slide => (
        slides.push(
        <SwiperSlide key={slide.idslide}>
            <div className='bg-black w-full h-screen absolute opacity-50'>
            </div>
            <div className='w-full h-screen absolute pt-10'>
                <div className='w-full h-screen'>
                    <blockquote>
                    <h1 className='text-white text-5xl text-center uppercase mt-10'>{slide.titulo}</h1>
                    </blockquote>
                    <p className='text-white text-2xl text-center mt-5'>{slide.contenido}</p>

                </div>
            </div>
            <img className='h-screen w-full object-cover' src={Url+slide.imagen} alt={slide.titulo}/>
        </SwiperSlide>
        )
    ));


    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            observer = {true}
            centeredSlides={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            
            speed={500}
            loop={true}
            touchRatio={1.5}
            effect={"fade"}
            className="mySwiper"
        >
            {slides}
        </Swiper>
  );
}

export default SliderPrincipal