import React, { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const FormNewOrder = () => {
    const [ cantidad, setCantidad ] = useState(1)
    const [ bloquear, setBloquear ] = useState(false)
    const [ modeloAux, setModeloAux ] = useState({})
    const { ordenes, setOrdenes, subModelo, setSubModelo, setViewOpen, mail } = useContext(ContextStates)

    useEffect(()=>{
        const checkCantidad = () => {
            let stock=subModelo.stock;
            if (stock<cantidad || cantidad==='')
            {
                setBloquear(true)
            }
            else
            {
                setBloquear(false)
            }
        }
        checkCantidad()
    },[cantidad])

    const handleAdd = () => {
        if (cantidad>=1)
        {
            setModeloAux({
                id:subModelo.id,
                descripcion:subModelo.descripcion,
                marca:subModelo.marca,
                descripcionproducto:subModelo.descripcionproducto,
                imagen:subModelo.imagen,    
                precio:subModelo.precio,
                cantidad
            })
            setSubModelo({cantidad})
            setViewOpen(false)
        }
    }

    useEffect(() => {
        const addProduct = async() => {
            if (modeloAux.id)
            {
                if (mail)
                {
                    const formData=new FormData()
                    formData.append('mail',mail);
                    formData.append('idmodelo',modeloAux.id);
                    formData.append('cantidad',modeloAux.cantidad);
                    formData.append('precio',modeloAux.precio);
                    try{
                        const results = await axios({
                            url: Url+'api/addProducto.php',
                            method: 'POST',
                            data: formData,
                        })
                        console.log(results)
                    } catch (e) {
                        console.log(e)
                    }
                }
                let rData = [...ordenes];
                let encontrado = 0;
                rData.forEach(element => {
                    if (element.id===modeloAux.id)
                    {
                        element.cantidad= parseInt(element.cantidad) + parseInt(modeloAux.cantidad);
                        encontrado=1;
                    }
                });
                if (encontrado===1)
                {
                    setOrdenes(rData)
                }
                else
                {    
                    rData.push(modeloAux)
                    setOrdenes(rData)       
                }
            }
        }
        addProduct()
    },[modeloAux])

  return (
    <div className='p-5 w-10/12 mx-auto'>
        <div className='w-1/3 mx-auto'>
            <img className="mx-auto" src={Url+subModelo.imagen}/>
        </div>
        <h3 className="text-center text-xl font-semibold uppercase">{subModelo.marca}</h3>
        <p className="text-center text-lg">{subModelo.descripcionproducto}</p>
        <p className="text-center text-lg">{subModelo.descripcion}</p>
        <div className='w-1/3 mt-2 mx-auto p-2 flex justify-center'>
            <label className='mt-1 mr-2 text-md' htmlFor='cantidad'>Cantidad</label>
            <input 
                id='cantidad' 
                className='text-center p-1 rounded-lg bg-slate-100' 
                type='number' 
                onChange={(e)=>setCantidad(e.target.value)} 
                value={cantidad}
            />
        </div>
        <div className='w-full sm:w-1/2 mx-auto'>
            <button 
                type='button' 
                className={`mt-5 w-full text-lg uppercase text-white p-2 rounded-xl ${bloquear ? 'bg-gray-400':'bg-blue-500'}`}
                disabled={bloquear}
                onClick={()=>handleAdd()}
            >Agregar al Carro</button>
        </div>
    </div>               
  )
}

export default FormNewOrder