import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { motion } from 'framer-motion';
import { areaAnimateLR } from './Animations';
import { Url } from "./Url";


const ProductList = () => {
    const products = useSelector(state=>state.products)

    return (
        <div className="w-10/12 mx-auto mt-20 mb-20 sm:grid grid-rows-6 grid-flow-col gap-4">
            { products.map((item,index)=>(
                <motion.div 
                    className="row-span-2 w-full mx-auto p-3 rounded-3xl hover:shadow-xl" key={index}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{once:true, amount: 0.1}}
                    variants={areaAnimateLR}
                >
                    <Link to={`producto/${item.id}`}>
                        <img className="h-64 object-contain mx-auto" src={Url+item.imagen}/>
                        <h3 className="text-center text-2xl font-semibold uppercase">{item.marca}</h3>
                        <p className="text-center text-xl">{item.descripcionproducto}</p>
                        <p className="text-center text-lg">{item.descripcion}</p>
                    </Link>
                </motion.div>
            ))}
        </div>
    )
}

export default ProductList