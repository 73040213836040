import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom"
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from './Animations';
import { Url } from './Url';


const CategoriasList = () => {
    const [ categorias, setCategorias ] = useState([]);

    useEffect(() => {
        const query = async () => {
            const url = `${Url}api/listadocategorias.php`;
            const resultado = await axios.get(url);
            setCategorias(resultado.data.results);
          }
          query();
    }, [])


    return (
        <div className="w-full mx-auto mt-20 mb-20 sm:grid grid-cols-4 gap-4">
            { categorias.map((item,index)=>(
                <motion.div 
                    className="p-5 w-full mx-auto" key={index}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{once:true, amount: 0.1}}
                    variants={areaAnimateLR}
                >
                    <Link to={`/categoria/${item.id}`}>
                        <img className="object-cover h-64 w-full rounded-2xl" src={Url+item.imagen}/>
                        <p className="text-center text-xl mt-auto">{item.descripcion}</p>
                    </Link>
                </motion.div>
            ))}
        </div>
  )
}

export default CategoriasList