import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const ViewPedido = () => {
    const [ pedido, setPedido ] = useState({})
    const { pedidoSelected, valPedido, setValPedido } = useContext(ContextStates)

    useEffect(()=>{
        const consultaPedido = async () => {
            const formData=new FormData()
            formData.append('id',pedidoSelected)
            try{
                const response = await axios({
                    url: Url+'api/estadoPedido.php',
                    method: 'POST',
                    data: formData,
            })
            //console.log(response)
            setPedido(response.data.results)
            } catch (e) {
                console.log(e)
            }    
        }
        consultaPedido()
    },[valPedido])

    const aprobarPago = async() => {
        const formData=new FormData()
        formData.append('id',pedidoSelected)
        try{
            const response = await axios({
                url: Url+'api/aprobarPago.php',
                method: 'POST',
                data: formData,
        })
        setValPedido(response.data.results.id)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="w-full py-5 px-2 md:px-20 rounded-2xl">
            <h2 className='text-center text-gray-700 text-xl'>Estado de Pedido</h2>
            <div className="sm:items-start">
            { pedido && (
                <div>
                    <h3 className='text-center text-xl'>Pedido Nº{pedido.numeropedido}</h3>
                    <p className='text-lg'>Apellido y Nombre: {`${pedido.apellido}, ${pedido.nombre}`}</p>
                    <p className='text-lg'>Teléfono: {pedido.telefono}</p>
                    <p className='text-lg'>E-mail: {pedido.mail}</p>
                    <p className='text-center'>Articulos</p>
                    <table className='w-3/4 mx-auto'>
                        <tr className='p-1 bg-blue-400 text-white text-center'><th>Cantiad</th><th>Articulo</th><th>Subtotal</th></tr>
                        {
                            pedido.articulos && pedido.articulos.map(item => (
                                <tr className='border border-gray-300 p-2 text-center'>
                                    <td>{item.cantidad}</td><td>{item.marca+' - '+item.descripcionproducto}</td><td>${item.precio*item.cantidad}</td>
                                </tr>
                            ))
                            
                        }
                        <tr className='font-semibold text-center'><td>Total:</td><td></td><td className='font-semibold'>${pedido.total}</td></tr>
                    </table>
                    
                    { pedido.pago==='200' && (
                        <div className='w-5/6 mx-auto'>
                            <p className='bg-orange-200 p-3 rounded-xl'>Pago pendiente de aprobación</p>
                            <button 
                                className='bg-green-600 mt-5 p-5 rounded-xl text-white text-xl uppercase text-center'
                                onClick={()=>aprobarPago()}
                            >Aprobar pago</button>
                            <img src={Url+pedido.imagen}/>
                        </div>
                    )}
                </div>

            )}
            </div>
        </div>
    )
}

export default ViewPedido