import React from 'react'
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animations';
import Layout from '../components/Layout'
import SliderPrincipal from '../components/SliderPrincipal'
import ProductList from '../components/ProductList'
import CategoriasList from '../components/CategoriasList';
import MarcasList from '../components/MarcasList';
import {Parallax} from 'react-parallax'
import img1 from '../assets/bg1.jpg'
import img2 from '../assets/bg2.jpeg'
import img3 from '../assets/bg3.webp'

const Index = () => {
    return (
        <Layout
            pagina="Inicio"
        >
            <main className='h-full'>
                <div className='w-full h-full bg-white'>
                    <div className='w-full md:flex mx-auto'>
                        <SliderPrincipal/>
                    </div>
                    <Parallax
                        className='h-screen' bgImage={img1} strength={300} blur={3} bgImageSizes='' 
                    >
                    </Parallax>
                    <div className='bg-white/60 w-full'>
                        <motion.h2 
                            className='text-center text-4xl mt-5 uppercase font-bold'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{once:true, amount: 0.1}}
                            variants={areaAnimateRL}
                        >Productos</motion.h2>
                        <ProductList/>
                    </div>
                    <Parallax
                        className='h-screen' bgImage={img2} strength={300} blur={3} bgImageSizes='' 
                    >
                    </Parallax>
                    <div className='bg-white/60 w-full'>
                        <motion.h2
                            className='text-center text-4xl mt-5 uppercase font-bold'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{once:true, amount: 0.1}}
                            variants={areaAnimateRL}
                        >Categorías</motion.h2>
                        <CategoriasList/>
                    </div>
                    <Parallax
                        className='h-screen' bgImage={img3} strength={300} blur={3} bgImageSizes='' 
                    >
                    </Parallax>
                </div>
            </main>
          
        </Layout>
    )
}

export default Index