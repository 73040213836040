import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addProduct } from '../features/products/productSlice'
import axios from 'axios'
import { Url } from './Url'

const ProductsLoad = () => {
    const [ isLoad, setIsLoad ] = useState(false)
    const [ auxProducts, setAuxProducts ] = useState([])
    const dispatch = useDispatch()
    useEffect(()=>{
        const getProducts= async () =>{
            const formData=new FormData()
            formData.append('busqueda',"")
            formData.append('idusuario',2)
            try{
                const response = await axios({
                    url: Url+'api/listadoproductos.php',
                    method: 'POST',
                    data: formData,
                })
                setAuxProducts(response.data.results)
                setIsLoad(true)
            } catch (e) {
                console.log(e)
            }        
        }
        if (!isLoad)
            getProducts()
    },[])
    useEffect(()=>{
        dispatch(addProduct(auxProducts))
    },[auxProducts])

}

export default ProductsLoad