import { useState, useEffect } from 'react'
import  io  from 'socket.io-client';
const socket = io('http://186.158.210.127:4000')

const FormMessages = () => {
    const [ message, setMessage ] = useState("")
    const [ messages, setMessages ] = useState([])
    const [ nickname, setNickname ] = useState(0)
    const [ dest, setDest ] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault()
        socket.emit('message',message,dest)
        setMessage("")
        const newMessage = {
            body: message,
            from: 'Yo'
        }
        setMessages([...messages,newMessage])
    }

    useEffect(()=>{
        const receiveMessage = (message) => {
            setMessages([...messages,message])
        }
        socket.on("session", ({ sessionID, nickname }) => {
            // attach the session ID to the next reconnection attempts
            socket.auth = { sessionID };
            // store it in the localStorage
            localStorage.setItem("sessionID", sessionID);
            // save the ID of the user
            socket.userID = nickname;
          });
        socket.on('message', receiveMessage)
        return () => {

            socket.off('message', receiveMessage)
        }
        
    },[messages])

    return (
        <div className='w-full min:h-screen bg-gray-300 md:p-20 p-5'>
            
                <input type="text" placeholder='user' value={nickname} onChange={(e)=>setNickname(e.target.value)}/>
                <input type="text" placeholder='Destinatario' value={dest} onChange={(e)=>setDest(e.target.value)}/>
                <div className='w-full mt-5 h-96 bg-white rounded-3xl p-5 overflow-y-auto'>
                    <ul>
                        { messages.map( (item, index) => (
                            <li key={index} className={`' my-2 p-3 rounded-xl table ' ${item.from==='Yo' ? 'bg-blue-300 items-end ml-auto' : 'bg-gray-300'}`}>
                                <p><span className='font-semibold'>{item.from}:</span> {item.body}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <form className='mt-2 mb-2 sm:p-10 flex' onSubmit={handleSubmit}>
                    <input className='w-10/12 rounded-xl p-3' placeholder='Mensaje...' type='text' value={message} onChange={(e)=>setMessage(e.target.value)} />
                    <button className='bg-blue-500 text-white rounded-xl ml-2 w-1/5 p-3' type='submit'>Enviar</button>
                </form>
            
        </div>
  )
}

export default FormMessages