import React, { useContext, useEffect } from 'react';
import authContext from '../context/auth/authContext';
import ContextStates from '../context/ContextStates';
import axios from 'axios';
import { Url } from './Url';


const CheckCart = () => {
    const { mail, ordenes, setOrdenes, setIdPedido, setPendientes } = useContext(ContextStates);
    const AuthContext = useContext( authContext);
	const { autenticado } = AuthContext;

	useEffect(() => {
		localStorage.setItem('localOrder',JSON.stringify(ordenes))
	},[ordenes])


	useEffect(() => {
		const checkCart = async() => {
			let array = JSON.parse( localStorage.getItem('localOrder'))
			if (mail)
			{
				const formData=new FormData()
				formData.append('mail',mail);
				formData.append('ordenes', JSON.stringify(ordenes));
				try{
					const results = await axios({
						url: Url+'api/checkCart.php',
						method: 'POST',
						data: formData,
					})
					console.log("ver aqui ",results)
					if (results.data.results.pago==='0')
					{
						setOrdenes(results.data.results.productos)
						setIdPedido(results.data.results.idpedido)
					}
					else if (results.data.results.pago==='10' || results.data.results.pago==='20')
					{
						setPendientes(true)
					}
				} catch (e) {
					console.log(e)
				}
			}
			else
			{
                if (array)
				{
					console.log("intenta agregar")
					setOrdenes(array)
					console.log("ordenes ahora ",ordenes)
				}
                else
                {
                    setOrdenes([])
                }
			}	
		}
		checkCart()
	},[mail, autenticado])


	return (
		<>
		</>
	)
}

export default CheckCart