import { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import SubirImagenes from '../SubirImagenes'
import axios from 'axios'
import { Url } from '../Url'


const FormModelo = () => {
    const [ idModelo, setIdModelo ] = useState(0)
    const [ productos, setProductos ] = useState([])
    const [ producto, setProducto ] = useState(0)
    const [ marcas, setMarcas ] = useState([])
    const [ marca, setMarca ] = useState(0)
    const { setValModelo } = useContext(ContextStates)

    let tipoImagen = "modelos";
    let idHtml = "imagenesModelos";
    const childRef = useRef()

    useEffect(()=>{
        const consultaMarcas = async () => {
            const url = Url+'api/listadomarcas.php';
            const resultado = await axios.get(url);
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id)
        }
        consultaMarcas()
    },[])

    useEffect(()=>{
        const consultaProductos = async () => {
            if (marca!==0)
            {
                const formData=new FormData()
                formData.append('idmarca',marca)
                try{
                    const response = await axios({
                        url: Url+'api/listProductos.php',
                        method: 'POST',
                        data: formData,
                })
                setProductos(response.data.results)
                setProducto(response.data.results[0].id)
                } catch (e) {
                    console.log(e)
                }        
    
            }
        }
        consultaProductos()
    },[marca])

    const formik = useFormik({
        initialValues:{
            descripcion: '',
            descripcionExt: '',
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                    .required("La descripcion es obligatoria"),            
            
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('descripcion',valores.descripcion);
            formData.append('descripcionExt',valores.descripcionExt);
            formData.append('idproducto',producto);
            try{
                const results = await axios({
                    url: Url+'api/altaModelos.php',
                    method: 'POST',
                    data: formData,
                })
                setIdModelo(results.data.results.id)
                setValModelo(results.data.results.id)
                resetForm()
            } catch (e) {
                console.log(e)
            }
        }    
    });

    useEffect(()=>{
        if (idModelo!==0)
        {
            childRef.current.handleSubmit();
        }
    },[idModelo])


return (
        <form
            className="w-full py-5 px-20 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Agregar Modelos</h2>
                <div className="sm:items-start">
                    <label htmlFor="sMarca" className="w-full block mt-5 text-black uppercase font-bold">Marca</label>
                    <select 
                        className='py-2 px-3 w-full rounded bg-slate-100' 
                        id='sMarca' 
                        onChange={(e)=>setMarca(e.target.value)}
                    >
                        { marcas && (
                            marcas.map((item, index) => (
                                <option key={index} value={item.id}>{item.descripcion}</option>))
                        ) }
                    </select>
                    <label htmlFor="sProducto" className="w-full block mt-5 text-black uppercase font-bold">Producto</label>
                    <select 
                        className='py-2 px-3 w-full rounded bg-slate-100' 
                        id='sProducto' 
                        onChange={(e)=>setProducto(e.target.value)}
                    >
                        { productos && (
                            productos.map((item, index) => (
                                <option key={index} value={item.id}>{item.descripcion}</option>))
                        ) }
                    </select>
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcion" className="w-full block mt-5 text-black uppercase font-bold">Descripción</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="descripcion" 
                        placeholder="Descripción" 
                        type="text" 
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    { formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="descripcionExt" className="block text-black uppercase font-bold">Detalles</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="descripcionExt" 
                        placeholder="Ingrese una descripción detallada" 
                        type="text" 
                        value={formik.values.descripcionExt}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.descripcionExt && formik.errors.descripcionExt ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcionExt}</p>
                        </div>
                    ) : null }
                </div>
            </div>
            <SubirImagenes 
                id={idModelo} 
                tipo={tipoImagen} 
                ref={childRef}
                idHtml={idHtml}
            />
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <input
                        type="submit"
                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        value="Guardar Datos"
                    />
                </div>
            </div>
        </form>
)
}

export default FormModelo