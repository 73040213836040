import React from 'react'
import Layout from '../components/Layout'
import SliderPrincipal from '../components/SliderPrincipal'
import ProductList from '../components/ProductList'
import FormMessages from '../components/FormMessages'

const Messages = () => {
    return (
        <Layout
            pagina="Inicio"
        >
            <main className='h-full'>
                <div className='w-full h-full bg-white'>
                    <div className='w-full mx-auto'>
                        <FormMessages/>
                    </div>
                </div>
            </main>
          
        </Layout>
    )
}

export default Messages