import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { areaAnimateLR,areaAnimateRL } from './Animations';
import ContextStates from '../context/ContextStates';
import authContext from '../context/auth/authContext';
import { Link } from 'react-router-dom';
import FormMP from './forms/FormMP';
import FormTransfer from './forms/FormTransfer';
import logoMP from '../assets/Mercado-pago.png';
import { Url } from './Url';
import logoViumi from '../assets/MAC-logo_viumi-Copy.png';


const ListCart = () => {
    const [ total, setTotal ] = useState(0)
    
    const [ mail, setMail ] = useState('');
    const { autenticado } = useContext(authContext);
    const { setFormView, setViewOpen, ordenes, ordenesPrev, medioPago, setMedioPago } = useContext(ContextStates);
    

    useEffect(()=>{
        if (localStorage.getItem('jjn_em'))
            setMail(localStorage.getItem('jjn_em'))
        else
            setMail('');
    },[autenticado])
    
    useEffect(()=>{
        let rData=[];
        if (ordenes.length>0)
        {
		    rData=[...ordenes];
        }
        else
        {
            rData=[...ordenesPrev];
        }
		let auxTotal=0;
		rData.forEach(element => {
			auxTotal=auxTotal+(parseInt(element.cantidad)*parseInt(element.precio))
		});

		setTotal(auxTotal)
	},[ordenes,ordenesPrev])

    const handleLogin = () => {
        setFormView(3);
        setViewOpen(true);
    }

    const handlePago = () => {

    }

    return (
        <>
            <div className='md:w-9/12 w-full mx-auto shadow-xl rounded-xl'>
                { (ordenes.length>0 ? ordenes : ordenesPrev).map ((item,index)=>(
                    <div className="sm:w-2/3 w-full mx-auto flex p-3 bg-slate-50 rounded-2xl mb-10" key={index}>
                        <div className='w-1/4'>
                            <motion.img className="" src={Url+item.imagen}
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimateLR}
                            />
                        </div>
                        <div className='w-3/4 pt-5 flex'>
                            <motion.div className='w-1/2 p-2'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimateRL}
                            >
                                <h3 className="text-center text-2xl font-semibold uppercase">{item.marca}</h3>
                                <p className="text-center text-xl">{item.descripcionproducto}</p>
                                <p className="text-center text-lg">{item.descripcion}</p>
                                <p className="mt-5 text-center text-lg font-semibold">Precio Unitario: $ {item.precio}</p>
                            </motion.div>
                            <motion.div className='w-1/2 p-2'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimateRL}
                            >
                                <p className="mt-5 text-center text-lg font-semibold">Cantidad: {item.cantidad}</p>
                                <p className="mt-5 text-center text-lg font-semibold">Subtotal: $ {parseInt(item.cantidad)*parseInt(item.precio)}</p>
                            </motion.div>
                        </div>
                    </div>
                ))}
                <div className="sm:w-2/3 w-full mx-auto flex bg-slate-100 p-20 rounded-xl">
                    <div className='w-full mx-auto'>
                        <p className='font-bold uppercase text-2xl text-center'>Total: $ {total}</p>
                    </div>
                </div>
                { mail!=='' ? (
                <>
                    <div className='sm:w-2/3 w-full mx-auto px-10 py-5 rounded-xl'>
                        <h4 className='text-center text-xl uppercase'>Medio de pago</h4>
                        <div className='w-full mt-5'>
                            <input
                                type="radio"
                                name="medioPago"
                                value="1"
                                id="transferencia"
                                checked={medioPago === 1}
                                onChange={()=>setMedioPago(1)}
                                className='mr-5'
                            />
                            <label htmlFor="transferencia">Transferencia</label>
                        </div>
                        <div className='w-full mt-5 flex'>
                            <input
                                type="radio"
                                name="medioPago"
                                value="2"
                                id="mp"
                                checked={medioPago === 2}
                                onChange={()=>setMedioPago(2)}
                                className='mr-5'
                            />
                            <label htmlFor="mp">
                                <img className='h-10' src={logoMP} />
                            </label>
                        </div>
                        
                    </div>
                    <div className='sm:w-2/3 w-full mx-auto px-10 py-5'>
                    {
                        medioPago!==0 && (
                            <Link to={`/pago`}>
                                <p className="w-full shadow-md p-3 bg-blue-600 hover:bg-blue-500 text-white text-center transition-all uppercase font-bold rounded-lg mt-5">Continuar con éste medio de Pago</p>
                            </Link>
                        )

                    }
                    </div>
                </>
                ) : (
                    <div className='sm:w-2/3 w-full mx-auto px-10 py-5 rounded-xl'>
                        <button
                            className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                            onClick={()=>handleLogin()}
                        >
                            Iniciar Sesión o Registrarme
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default ListCart