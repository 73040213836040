import { useState, useEffect } from 'react'
import axios from 'axios'
import { Url } from '../Url'


const FormPrecios = () => {
    const [ marcas, setMarcas ] = useState([])
    const [ marca, setMarca ] = useState(0)
    const [ porcentaje, setPorcentaje ] = useState('')

    useEffect(()=>{
        const consultaMarcas = async () => {
            const url = Url+'api/listadomarcas.php?marcacero=1';
            const resultado = await axios.get(url);
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id)
        }
        consultaMarcas()
    },[])


    const setPrecios = async()=> {
        if (porcentaje!==0)
        {
            const formData=new FormData()
            formData.append('porcentaje',porcentaje);
            formData.append('idmarca',marca);
            
            try{
                const results = await axios({
                    url: Url+'api/setPrecios.php',
                    method: 'POST',
                    data: formData,
                })
                setMarca(0)
                setPorcentaje('')
            } catch (e) {
                console.log(e)
            }    
        }
    }

    return (
        <div>
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Modificación de precios</h2>
                <div className="sm:items-start">
                    <label htmlFor="sMarca" className="w-full block mt-5 text-black uppercase font-bold">Marca</label>
                    <select 
                        className='py-2 px-3 w-full rounded bg-slate-100' 
                        id='sMarca' 
                        value={marca}
                        onChange={(e)=>setMarca(e.target.value)}
                    >
                        { marcas && (
                            marcas.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>))
                        ) }
                    </select>
                    <label htmlFor="porcentaje" className="block mt-5 text-black uppercase font-bold">Porcentaje de Incremento (%)</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="porcentaje" 
                        placeholder="Ingrese el porcentaje de incremento" 
                        type="text" 
                        value={porcentaje}
                        onChange={(e)=>setPorcentaje(e.target.value)}
                        autoFocus
                    />
                </div>
            </div>
            <div className="mt-auto">
                <div className="w-full rounded-lg px-3">
                    <button
                        type="button"
                        className={`w-full shadow-md p-3 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5 ${((porcentaje!=='0')&&(porcentaje!==''))  ? 'bg-orange-600':'bg-gray-300'} `}
                        onClick={()=>setPrecios()}
                    >
                        Realizar Cambios
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormPrecios