import { useState, useEffect, useContext } from 'react';
import ContextStates from '../context/ContextStates';
import { motion } from 'framer-motion';
import {FaInstagram,FaFacebook,FaWhatsapp} from 'react-icons/fa'
import {HiOutlineLocationMarker,HiOutlineMail,HiOutlinePhone} from 'react-icons/hi'
import { Link } from 'react-router-dom';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from './Animations';
import Logo from '../assets/logoblanco.png'
import axios from 'axios';
import { Url } from './Url';


const Footer = () => {
    const [ categorias, setCategorias ] = useState([]);
    const { contactos, setContactos } = useContext(ContextStates);

    const today = new Date();
    const year = today.getFullYear();


    useEffect(() => {
        const query = async () => {
            const url = `${Url}api/listadocategorias.php`;
            const resultado = await axios.get(url);
            setCategorias(resultado.data.results);
          }
          query();
    }, [])


    return (
        <motion.footer className="bg-golden text-center lg:text-left text-gray-200"
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{once:true, amount: 0.1}}
            variants={areaAnimateS}
        >
            <div className="flex justify-center items-center lg:justify-between p-6 border-b border-gray-300">
                <div className="mr-12 hidden lg:block">
                    <span>Conectate con nosotros en nuestras redes sociales:</span>
                </div>
                <div className="flex justify-center">
                    { typeof contactos[2]!=='undefined' && (
                    <>
                    <a 
                        href={contactos[2].contacto}
                        className="mr-6 text-gray-200"
                        target={"_blank"}
                    >
                        <FaFacebook />
                    </a>
                    <a 
                        href={contactos[3].contacto}
                        className="mr-6 text-gray-200"
                        target={"_blank"}
                    >
                        <FaInstagram />
                    </a>
                    <a 
                        href={`https://wa.me/${contactos[0].contacto}`} 
                        className="mr-6 text-gray-200"
                        target={"_blank"}
                    >
                        <FaWhatsapp />
                    </a>
                    </>
                    )}
                </div>
            </div>
            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateLR}
                    >
                        <Link to="/">
                            <img className='mx-auto' src={Logo} alt="Gabi Rotondo"></img>
                        </Link>
                    </motion.div>
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateLR}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Productos
                        </h6>
                        { categorias.map((item,index)=>(
                            <p className="mb-4" key={index}>
                                <Link to={`/categoria/${item.id}`}>
                                    <p className="text-gray-200">{item.descripcion}</p>
                                </Link>
                            </p>
                        ))}
                    </motion.div>
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateRL}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Enlaces útiles
                        </h6>
                        <p className="mb-4">
                            <Link to="/productos" className="text-gray-200">Productos</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="/promociones" className="text-gray-200">Promociones</Link>
                        </p>
                        <p>
                            <Link to="/nosotros" className="text-gray-200">Nosotros</Link>
                        </p>
                    </motion.div>
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateRL}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Contacto
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlineLocationMarker className='mr-1' />
                            {typeof contactos[4]!=='undefined' && contactos[4].contacto}
                        </p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlineMail className='mr-1' />
                            {typeof contactos[1]!=='undefined' && contactos[1].contacto}
                        </p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlinePhone className='mr-1' />
                            {typeof contactos[0]!=='undefined' && contactos[0].vista}
                        </p>
                        
                        <a
                            className="flex items-center justify-center md:justify-start mb-4"
                            href={typeof contactos[0]!=='undefined' && `https://wa.me/${contactos[0].conctacto}`}
                            target={"_blank"}
                            rel='noreferrer'
                        >
                            <FaWhatsapp className="mr-1" aria-hidden="true" />
                            {typeof contactos[0]!=='undefined' && contactos[0].vista}
                        </a>
                        
                    </motion.div>
                </div>
            </div>
            <motion.div 
                className="text-center p-6"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{once:true, amount: 0.1}}
                variants={areaAnimateRL}
            >
                <span>© {year} Copyright:</span>
                <Link className="text-gray-300 font-semibold" to="/">Gabi Rotondo</Link>
            </motion.div>
            <motion.div 
                className="text-center p-6"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{once:true, amount: 0.1}}
                variants={areaAnimateRL}
            >
                <a className="text-gray-300 font-semibold" href="https://mirx.com.ar" target={"_blank"}><span className='font-light'>Diseño y Desarrollo </span>Mirx Software</a>
            </motion.div>
        </motion.footer>  
    )
}

export default Footer
