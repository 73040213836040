import Layout from '../../components/admin/Layout';
import ListadoPedidos from '../../components/admin/listadoPedidos';

const AdminPedidos = () => {
	return (
		<Layout
			pagina={"Producto"}
		>
			<div className='w-full p-5 min-h-full'>
				<ListadoPedidos/>
			</div>
		</Layout>
	)
}

export default AdminPedidos