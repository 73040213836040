import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'


const FormCuentasBank = () => {
    const [ cuentas, setCuentas ] = useState([])
    const [ edit, setEdit ] = useState(false)
    const [ entidad, setEntidad ] = useState('')
    const [ nombre, setNombre ] = useState('')
    const [ cbu, setCbu ] = useState('')
    const [ alias, setAlias ] = useState('')
    const [ cuentaSelected, setCuentaSelected ] = useState({})
    const [ add, setAdd ] = useState(false)

    
    useEffect(()=>{
        const getCuentas = async() => {
            const url = `${Url}api/listCuentas.php`;
            const resultado = await axios.get(url);
            setCuentas(resultado.data.results);
        }
        getCuentas()
    },[])

    const formik = useFormik({
        initialValues:{
            entidad: '',
            nombre: '',
            cbu: '',
            alias: ''
        },
        validationSchema: Yup.object({
            entidad: Yup.string()
                    .required("Es necesario ingresar un Nombre de Entidad"), 
            nombre: Yup.string()
                    .required("Es necesario ingresar un Nombre de Titular"),            
            cbu:  Yup.string()
            .required("Es necesario ingresar un CBU/CVU"),
            alias:  Yup.string()
            .required("Es necesario ingresar un Alias") 
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('entidad',valores.entidad);
            formData.append('nombre',valores.nombre);
            formData.append('cbu',valores.cbu);
            formData.append('alias',valores.alias);
            try{
                const results = await axios({
                    url: Url+'api/altaCuenta.php',
                    method: 'POST',
                    data: formData,
                })
                setCuentas(results.data.results);
                resetForm()
                setAdd(false)
            } catch (e) {
                console.log(e)
            }
        }    
    });

    const handleEdit = (item) => {
        setCuentaSelected({
            id:item.id,
            entidad:item.entidad,
            nombre:item.nombretitular,
            cbu:item.cbu,
            alias:item.alias
        })
        setEntidad(item.entidad)
        setNombre(item.nombretitular)
        setCbu(item.cbu)
        setAlias(item.alias)
        setEdit(true)
    }

    const setCuenta = async() => {
        const formData=new FormData()
        formData.append('id',cuentaSelected.id);
        formData.append('entidad',entidad);
        formData.append('nombre',nombre);
        formData.append('cbu',cbu);
        formData.append('alias',alias);
        try{
            const results = await axios({
                url: Url+'api/setCuenta.php',
                method: 'POST',
                data: formData,
            })
            setCuentas(results.data.results);
            setEdit(false)
        } catch (e) {
            console.log(e)
        }
    }

    const delCuenta = async(id) => {
        const formData=new FormData()
        formData.append('id',id);
        try{
            const results = await axios({
                url: Url+'api/delCuenta.php',
                method: 'POST',
                data: formData,
            })
            setCuentas(results.data.results);
            setEdit(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
        <div className='w-full'>
            <div className="transition-all w-full pt-5 p-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Cuentas para transferencias</h2>
                { cuentas && cuentas.map(item=> (
                    <div key={item.id} className='w-full md:w-1/2 mx-auto border-2xl bg-slate-100 mb-10 rounded-xl'>
                        { (edit && item.id===cuentaSelected.id) ? (
                        <>
                        <label htmlFor='entidad' className='uppercase text-md text-black'>Nombre de la Entidad</label>
                        <input type='text' id='entidad' value={entidad} placeholder='Nombre de Entidad' className='mb-3 w-full text-sm' onChange={(e)=>setEntidad(e.target.value)}/>
                        <label htmlFor='nombre' className='uppercase text-md text-black'>Nombre del Titular</label>
                        <input type='text' id='nombre' value={nombre} placeholder='Nombre del Titular' className='mb-3 w-full text-sm' onChange={(e)=>setNombre(e.target.value)}/>
                        <label htmlFor='cbu' className='uppercase text-md text-black'>CBU/CVU</label>
                        <input type='text' id='cbu' value={cbu} placeholder='CBU/CVU' className='mb-3 w-full text-sm' onChange={(e)=>setCbu(e.target.value)}/>
                        <label htmlFor='alias' className='uppercase text-md text-black'>Alias</label>
                        <input type='text' id='alias' value={alias} placeholder='Alias' className='mb-3 w-full text-sm' onChange={(e)=>setAlias(e.target.value)}/>
                        <button 
                            className='bg-blue-500 text-white p-3 rounded text-center'
                            onClick={()=>setCuenta()}
                        >Guardar
                        </button>
                        <button 
                            className='ml-5 bg-red-500 text-white p-3 rounded text-center'
                            onClick={()=>setEdit(false)}
                        >Cancelar
                        </button>
                        </>
                        ): (
                        <>
                        <p className='text-black mb-4'>Entidad: {item.entidad}</p>
                        <p className='text-black mb-4'>Nombre del Titular: {item.nombretitular}</p>
                        <p className='text-black mb-4'>CBU/CVU: {item.cbu}</p>
                        <p className='text-black mb-4'>Alias: {item.alias}</p>
                        <button 
                            type='button' 
                            className='bg-orange-500 text-white p-3 rounded text-center'
                            onClick={()=>handleEdit(item)}
                        >
                            Editar
                        </button>
                        <button 
                            type='button' 
                            className='ml-5 bg-red-500 text-white p-3 rounded text-center'
                            onClick={()=>delCuenta(item.id)}
                        >
                            Eliminar
                        </button>
                        </>
                        )}
                    </div>
                ))}
            </div>
        </div>
        {
            add ? (
                <form
                className="w-full mt-10 py-5 px-2 md:px-20 bg-white rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
                <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                    <h2 className='text-center text-gray-700 text-xl'>Agregar Cuenta</h2>
                    <div className="w-full md:w-2/3 mx-auto bg-slate-200 rounded-xl p-5">
                        <div className="mt-auto">
                            <label htmlFor='entidad' className='uppercase text-md text-black'>Nombre de la Entidad</label>
                            <input 
                                type='text' 
                                id='entidad' 
                                value={formik.values.entidad} 
                                placeholder='Nombre de Entidad' 
                                className='mb-3 w-full text-sm'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.entidad && formik.errors.entidad ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.entidad}</p>
                                </div>
                            ) : null }

                            <label htmlFor='nombre' className='uppercase text-md text-black'>Nombre del Titular</label>
                            <input 
                                type='text' 
                                id='nombre' 
                                value={formik.values.nombre} 
                                placeholder='Nombre del Titular' 
                                className='mb-3 w-full text-sm'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.nombre && formik.errors.nombre ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.nombre}</p>
                                </div>
                            ) : null }

                            <label htmlFor='cbu' className='uppercase text-md text-black'>CBU/CVU</label>
                            <input 
                                type='text' 
                                id='cbu' 
                                value={formik.values.cbu} 
                                placeholder='CBU/CVU' 
                                className='mb-3 w-full text-sm'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.cbu && formik.errors.cbu ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.cbu}</p>
                                </div>
                            ) : null }

                            <label htmlFor='alias' className='uppercase text-md text-black'>Alias</label>
                            <input 
                                type='text' 
                                id='alias' 
                                value={formik.values.alias} 
                                placeholder='Alias' 
                                className='mb-3 w-full text-sm'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.alias && formik.errors.alias ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.alias}</p>
                                </div>
                            ) : null }

                            <div className="w-full rounded-lg px-3">
                                <input
                                    type="submit"
                                    className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                                    value="Guardar Datos"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>    
            ):(
                <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                    <div className="sm:items-start">
                        <div className="mt-auto">
                            <div className="w-full md:w-1/3 mx-auto rounded-lg px-3">
                                <button
                                    type="submit"
                                    className="w-full shadow-md p-3 bg-blue-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                                    onClick={()=>setAdd(true)}
                                >Agregar Nueva Cuenta</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    )
}

export default FormCuentasBank