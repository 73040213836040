import { useState } from "react";
import AuthState from "./context/auth/authState";
import ProductsLoad from "./components/ProductsLoad";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ModalView from "./components/ModalView";
import Index from "./pages/Index";
import Producto from "./pages/Producto";
import Categoria from "./pages/Categoria";
import Marca from "./pages/Marca";
import Cart from "./pages/Cart";
import Resumen from "./pages/Resumen";
import ContextStates from "./context/ContextStates";
import Login from "./pages/Login";
import Results from "./pages/Results";
import Messages from "./pages/Messages";
import CheckCart from "./components/CheckCart";
import Pago from "./pages/Pago";
import Pedidos from "./pages/Pedidos";
import Search from "./pages/Search";
import IndexAdmin from "./pages/admin/Index";
import LoginAdmin from "./pages/admin/Login";
import AltaProductos from "./pages/admin/altaProductos";
import AdminPedidos from "./pages/admin/pedidos";
import Articulos from "./pages/admin/articulos";
import Configuracion from "./pages/admin/configuracion";
import GestionArticulos from "./pages/admin/gestionArticulos";
import Ventas from "./pages/admin/ventas";

function App() {
	const [ordenes, setOrdenes] = useState([]);
	const [viewOpen, setViewOpen] = useState(false);
	const [formView, setFormView] = useState(0);
	const [subModelo, setSubModelo] = useState({});
	const [mail, setMail] = useState(null);
	const [medioPago, setMedioPago] = useState(0);
	const [idPedido, setIdPedido] = useState(0);
	const [pedidos, setPedidos] = useState([]);
	const [ordenesPrev, setOrdenesPrev] = useState([]);
	const [file, setFile] = useState([]);
	const [imagenSubida, setImagenSubida] = useState(0);
	const [estadoPedido, setEstadoPedido] = useState(0);
	const [viewSearch, setViewSearch] = useState(false);
	const [textSearch, setTextSearch] = useState('');

	const [viewAdminOpen, setViewAdminOpen] = useState(0);
	const [formAdminView, setFormAdminView] = useState(false);

	const [valMarca, setValMarca] = useState(0);
	const [valProducto, setValProducto] = useState(0);
	const [valModelo, setValModelo] = useState(0);
	const [valPedido, setValPedido] = useState(0);
	const [pedidoSelected, setPedidoSelected] = useState(0)
	const [subModeloSelected, setSubModeloSelected] = useState({})
	const [idArticulo, setIdArticulo] = useState(0)
	const [pendientes, setPendientes] = useState(false)
	const [ contactos, setContactos ] = useState([]);


	return (

		<AuthState>
			<ContextStates.Provider
				value={{
					ordenes, setOrdenes,
					viewOpen, setViewOpen,
					formView, setFormView,
					subModelo, setSubModelo,
					mail, setMail,
					medioPago, setMedioPago,
					idPedido, setIdPedido,
					pedidos, setPedidos,
					ordenesPrev, setOrdenesPrev,
					file, setFile,
					imagenSubida, setImagenSubida,
					estadoPedido, setEstadoPedido,
					viewSearch, setViewSearch,
					textSearch, setTextSearch,

					viewAdminOpen, setViewAdminOpen,
					formAdminView, setFormAdminView,
					valMarca, setValMarca,
					valProducto, setValProducto,
					valModelo, setValModelo,
					valPedido, setValPedido,
					pedidoSelected, setPedidoSelected,
					subModeloSelected, setSubModeloSelected,
					idArticulo, setIdArticulo,
					pendientes, setPendientes,
					contactos, setContactos
				}}
			>
				<ProductsLoad />
				<BrowserRouter>
					<Routes>
						<Route path='/producto/:id' element={<Producto />} />
						<Route path='/results/:status' element={<Results />} />
						<Route path='/categoria/:id' element={<Categoria />} />
						<Route path='/marca/:id' element={<Marca />} />
						<Route path='/' element={<Index />} />
						<Route path='/cart' element={<Cart />} />
						<Route path='/pago' element={<Pago />} />
						<Route path='/pedidos' element={<Pedidos />} />
						<Route path='/mensajes' element={<Messages />} />
						<Route path='/resumen' element={<Resumen />} />
						<Route path='/login' element={<Login />} />
						<Route path='/search' element={<Search />} />
						<Route path='/admin/' element={<IndexAdmin />} />
						<Route path='/admin/login' element={<LoginAdmin />} />
						<Route path='/admin/altaproductos' element={<AltaProductos />} />
						<Route path='/admin/pedidos' element={<AdminPedidos />} />
						<Route path='/admin/articulos' element={<Articulos />} />
						<Route path='/admin/configuracion' element={<Configuracion />} />
						<Route path='/admin/gestionarticulos' element={<GestionArticulos />} />
						<Route path='/admin/ventas' element={<Ventas />} />
					</Routes>
				</BrowserRouter>
				<ModalView />
				<CheckCart />
			</ContextStates.Provider>
		</AuthState>

	);
}

export default App;

