import React, { useState, useEffect, useRef, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import SubirImagenes from '../SubirImagenes'
import axios from 'axios'
import { Url } from '../Url'

const FormTransfer = () => {
    const [ datos, setDatos ] = useState({})
    const [ total, setTotal ] = useState(0)
    const [ estadoActualizado, setEstadoActualizado ] = useState(false)
    const { mail, idPedido, imagenSubida, setImagenSubida, file, setOrdenes, setEstadoPedido, setPendientes } = useContext(ContextStates);

    let tipoImagen = "pedidos";
    let idHtml = "imagenesPedidos";
    const childRef = useRef()

    useEffect(() => {
        const generarPago = async () => {
            console.log(idPedido)
            const formData=new FormData()
            formData.append('mail',mail);
            formData.append('idpedido',idPedido);
            try{
                const results = await axios({
                    url: Url+'api/pagoTransfer.php',
                    method: 'POST',
                    data: formData,
                })               
                console.log(results)    
                setDatos(results.data.results.datoscuenta)
                setTotal(results.data.results.total)
            } catch (e) {
                console.log(e)
            }    
        }
        generarPago()
    }, []);

    useEffect(() => {
        const actualizarPago = async() => {
            const formData=new FormData()
            formData.append('idpedido',idPedido);
            formData.append('estado',200)
            try{
                const results = await axios({
                    url: Url+'api/actualizarPago.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results)            
                setEstadoPedido(results.data.results.estado)
                setImagenSubida(0)
                setEstadoActualizado(true)
                setOrdenes([])
                setPendientes(false)
            } catch (e) {
                console.log(e)
            }
        }
        if ((imagenSubida===idPedido) && idPedido!==0)
        {
            actualizarPago()
        }
    },[imagenSubida])

    const subirImagen = () => {
        childRef.current.handleSubmit();
    }

    return (
        <div className='w-10/12 mx-auto'>
            <div className='w-3/4 mt-2 mx-auto p-5 justify-center'>
                <h1 className='text-xl text-center'>Datos para realizar la transferencia</h1>
                { Array.isArray(datos) && datos.map(item=>(
                    <div className='p-3 w-full rounded-xl bg-slate-100 mb-10'>
                        <p className='text-lg mt-2'>Entidad Bancaria: <span className='font-semibold'>{item.entidad}</span></p>
                        <p className='text-lg mt-2'>Titular: <span className='font-semibold'>{item.nombretitular}</span></p>
                        <p className='text-lg mt-2'>CBU / CVU: <span className='font-semibold'>{item.cbu}</span></p>
                        <p className='text-lg mt-2'>Alias: <span className='font-semibold'>{item.alias}</span></p>
                    </div>
                ))}
                <p className='text-xl font-bold mt-2'>Total: ${total}</p>
            </div>
            <div className={`w-3/4 mt-5 mx-auto p-5 justify-center rounded-3xl ${!estadoActualizado ? 'bg-orange-200' : 'bg-green-200' } `}>
                {!estadoActualizado ? (
                    <p className='text-xl text-center text-gray-700'>Recuerde adjuntar el comprobante de Transferencia para poder acreditar su pago. Muchas gracias!</p>
                ):(
                    <p className='text-xl text-center text-gray-700'>El comprobante se subió correctamente, por favor aguarde un máximo de 24 Hs para que el pago sea aprovado. Muchas gracias!</p>
                )}
            </div>
            <div className='mt-5 w-3/4 mx-auto'>
                { !estadoActualizado && (
                    <SubirImagenes 
                        id={idPedido} 
                        tipo={tipoImagen} 
                        ref={childRef}
                        idHtml={idHtml}
                    />
                    )
                }
            </div>
            { file.length > 0 && (
            <div className='mt-5 w-3/4 mx-auto'>
                <button 
                    className='bg-blue-600 w-full text-white text-center p-3 rounded-xl'
                    onClick={()=>subirImagen()}
                >Subir Comprobante</button>
            </div>
            )}
        </div>               
    )
}

export default FormTransfer