import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'


const FormCuentaMP = () => {
    const [ token, setToken ] = useState('')
    const [ edit, setEdit ] = useState(false)
    
    useEffect(()=>{
        const getCuenta = async() => {
            const url = `${Url}api/listCtaMP.php`;
            const resultado = await axios.get(url);
            setToken(resultado.data.results.token);
        }
        getCuenta()
    },[])


    const setCuenta = async() => {
        const formData=new FormData()
        formData.append('token',token);
        try{
            const results = await axios({
                url: Url+'api/setCuentaMP.php',
                method: 'POST',
                data: formData,
            })
            setToken(results.data.results.token);
            setEdit(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
        <div>
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                <h2 className='text-center text-gray-700 text-xl'>Credenciales de Mercado Pago</h2>
                <p className='mt-5'>Las credenciales de Mercado Pago se pueden encontrar en la siguiente ruta:</p>
                <p className='mt-5 mb-5 font-bold'>{`Mercado Pago > Tu Negocio > Configuración > Credenciales (En Gestión y Administración) > Credenciales de Producción`}</p>
                <div className='w-full mx-auto border-2xl p-3 bg-slate-100 mb-10 rounded-xl'>
                    { edit ? (
                    <>
                    <label htmlFor='tkprod' className='uppercase text-md text-black'>Access Token</label>
                    <input type='text' id='tkprod' value={token} placeholder='Access Token (Producción)' className='mb-3 w-full text-sm' onChange={(e)=>setToken(e.target.value)}/>
                    <button 
                        className='bg-blue-500 text-white p-3 rounded text-center'
                        onClick={()=>setCuenta()}
                    >Guardar
                    </button>
                    <button 
                        className='ml-5 bg-red-500 text-white p-3 rounded text-center'
                        onClick={()=>setEdit(false)}
                    >Cancelar
                    </button>
                    </>
                    ): (
                    <>
                    <p className='text-black mb-4'>Access Token: {token}</p>
                    <button 
                        type='button' 
                        className='bg-orange-500 text-white p-3 rounded text-center'
                        onClick={()=>setEdit(true)}
                    >
                        Editar
                    </button>
                    </>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export default FormCuentaMP