import { useState } from 'react';
import Layout from '../../components/admin/Layout';
import FormCuentasBank from '../../components/admin/formCuentasBank';
import FormCuentaMP from '../../components/admin/formCuentaMP';
import FormPerfil from '../../components/admin/formPerfil';

const Configuracion = () => {
	const [ tab, setTab ] = useState(0)
	return (
		<Layout
			pagina={"Configuración"}
		>
			<div className='w-full'>
			<div className='w-full flex bg-black'>
				<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab===0 ? 'bg-white text-black':'bg-gray-800 text-white'}`} onClick={()=>setTab(0)}>Cuentas transferencias</button>
				<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab===1 ? 'bg-white text-black':'bg-gray-800 text-white'}`} onClick={()=>setTab(1)}>Mercado Pago</button>
				<button className={`text-center p-2 rounded-tl-xl rounded-tr-xl mr-1 ${tab===2 ? 'bg-white text-black':'bg-gray-800 text-white'}`} onClick={()=>setTab(2)}>Perfil</button>
			</div>
			<div className='w-full p-2 min-h-full'>
				{ tab===0 ? (
					<FormCuentasBank/>
				):tab===1 ? (
					<FormCuentaMP/>
				):(
					<FormPerfil/>
				)}
			</div>
			</div>
		</Layout>
	)
}

export default Configuracion