import React, { useContext, useEffect } from 'react';
import ContextStates from '../context/ContextStates';
import { motion } from 'framer-motion';
import { areaAnimateLR } from './Animations';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Url } from './Url';


const ListPedidos = () => {
    const { mail, pedidos, setPedidos, setOrdenesPrev, setIdPedido, estadoPedido } = useContext(ContextStates);
	const navigate = useNavigate();


	useEffect(() => {
		const checkPedidos = async() => {
			if (mail)
			{
				const formData=new FormData()
				formData.append('mail',mail);
				try{
					const results = await axios({
						url: Url+'api/listadoPedidos.php',
						method: 'POST',
						data: formData,
					})
					setPedidos(results.data.results);
				} catch (e) {
					console.log(e)
				}
			}
		}
		checkPedidos()
	},[estadoPedido])


	const handlePago = async(id) => {
		const formData=new FormData()
		console.log(id)
		formData.append('idpedido',id);
		try{
			const results = await axios({
				url: Url+'api/datosPedido.php',
				method: 'POST',
				data: formData,
			})
			setOrdenesPrev(results.data.results);
			setIdPedido(id)
			navigate("/resumen", { replace: true });
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<div className="w-full md:w-10/12 mx-auto mt-20 mb-20">
            { 	pedidos && pedidos.map((item,index)=>(
                <motion.div 
                    className="w-full sm:w-2/3 mx-auto mb-5" key={index}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{once:true, amount: 0.1}}
                    variants={areaAnimateLR}
                >
					<div className={`w-full rounded-2xl p-5 ${(item.pago==='10' || item.pago==='20') ? 'bg-red-100' : item.pago==='200' ? 'bg-green-100' : 'bg-slate-100'} `}>
						<h3 className="text-center text-2xl uppercase">Pedido Nº {item.numeropedido} {(item.pago==='10' || item.pago==='20') ? '- Pendiente de Pago' : item.pago==='200' && '- Pago en espera de aprobación'}</h3>
						<p className="text-center text-xl">Fecha del pedido: {item.fechapedido}</p>
						<p className="text-center text-lg">Total: ${item.total}</p>
						<div className='w-1/3 mx-auto'>
						{ (item.pago==='10' || item.pago==='20') && (
							<button
								className='bg-orange-500 w-full text-white p-3 rounded-xl text-xl text-center uppercase shadow-lg'
								onClick={()=>handlePago(item.idpedido)}
							>Realizar pago
							</button>
						)}
						</div>
					</div>
                </motion.div>
            ))}
        </div>
	)
}

export default ListPedidos