import { useContext } from 'react';
import ContextStates from '../context/ContextStates';
import Layout from '../components/Layout';
import FormTransfer from '../components/forms/FormTransfer';
import FormMP from '../components/forms/FormMP';


const Pago = () => {
    const { medioPago, setMedioPago } = useContext(ContextStates)

	return (
		<Layout
			pagina={"Mi compra"}
		>
			<div className='w-full pt-20 pb-20'>
                { medioPago===1 ? (
                    <FormTransfer/>
                ) : (
                    <FormMP/>
                )}
			</div>
		</Layout>
	)
}

export default Pago