import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios'
import { Url } from '../Url'
import { FaPlus } from 'react-icons/fa'


const FormSubModelo = () => {
    const [ modelos, setModelos ] = useState([])
    const [ modelo, setModelo ] = useState(0)
    const [ productos, setProductos ] = useState([])
    const [ producto, setProducto ] = useState(0)
    const [ marcas, setMarcas ] = useState([])
    const [ marca, setMarca ] = useState(0)
    const { setViewOpen, setFormView, valMarca, valProducto, valModelo } = useContext(ContextStates)
    
    useEffect(()=>{
        const consultaMarcas = async () => {
            const url = Url+'api/listadomarcas.php';
            const resultado = await axios.get(url);
            setMarcas(resultado.data.results);
            setMarca(resultado.data.results[0].id)
        }
        consultaMarcas()
    },[valMarca])

    useEffect(()=>{
        const consultaProductos = async () => {
            if (marca!==0)
            {
                const formData=new FormData()
                formData.append('idmarca',marca)
                try{
                    const response = await axios({
                        url: Url+'api/listProductos.php',
                        method: 'POST',
                        data: formData,
                })
                setProductos(response.data.results)
                setProducto(response.data.results[0].id)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultaProductos()
    },[marca, valProducto])

    useEffect(()=>{
        const consultaModelos = async () => {
            const formData=new FormData()
            formData.append('idproducto',producto)
            try{
                const response = await axios({
                    url: Url+'api/listModelos.php',
                    method: 'POST',
                    data: formData,
            })
            console.log("resultado ",response)
            setModelos(response.data.results)
            setModelo(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }        
        }
        consultaModelos()
    },[producto, valModelo])

    const formik = useFormik({
        initialValues:{
            descripcion: '',
            precio: '',
            stock: '',
            stockMin: ''
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                    .required("La descripcion es obligatoria"),            
            precio: Yup.number()
                    .required("Por favor ingrese un precio"),
            stock: Yup.number()
                    .required("Por favor ingrese un stock"),
            stockMin: Yup.number()
                    .required("Por favor ingrese un stock mínimo"),    
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('descripcion',valores.descripcion);
            formData.append('precio',valores.precio);
            formData.append('stock',valores.stock);
            formData.append('stockMin',valores.stockMin);
            formData.append('idmodelo',modelo);
            try{
                const results = await axios({
                    url: Url+'api/altaSubModelos.php',
                    method: 'POST',
                    data: formData,
                })
                resetForm()
                
            } catch (e) {
                console.log(e)
            }
        }    

    });

    const handleAlta = (item) => {
        setFormView(item)
        setViewOpen(true)
    }

return (
        <form
            className="w-full md:w-5/6 mx-auto py-5 px-3 md:px-20 rounded-2xl bg-slate-50"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full pt-5 sm:p-3 mt-5">
            <h2 className='text-center text-gray-700 text-xl'>Agregar Artículos</h2>
                <div className="">
                    <label htmlFor="sMarca" className="w-full block mt-5 text-black uppercase font-bold">Marca</label>
                    <div className='w-full flex'>
                        <select 
                            className='py-2 px-3 w-11/12 rounded bg-slate-100' 
                            id='sMarca' 
                            onChange={(e)=>setMarca(e.target.value)}
                        >
                            { marcas && (
                                marcas.map((item, index) => (
                                    <option key={index} value={item.id}>{item.descripcion}</option>))
                            ) }
                        </select>
                        <button 
                            type='button' 
                            className='w-1/12 bg-red-500 p2 rounded-lg'
                            onClick={()=>handleAlta(4)}
                        >
                            <FaPlus className='mx-auto text-white'/>
                        </button>
                    </div>
                    <label htmlFor="sProducto" className="w-full block mt-5 text-black uppercase font-bold">Producto</label>
                    <div className='w-full flex'>
                        <select 
                            className='py-2 px-3 w-11/12 rounded bg-slate-100' 
                            id='sProducto' 
                            onChange={(e)=>setProducto(e.target.value)}
                        >
                            { productos && (
                                productos.map((item, index) => (
                                    <option key={index} value={item.id}>{item.descripcion}</option>))
                            ) }
                        </select>
                        <button 
                            type='button' 
                            className='w-1/12 bg-red-500 p2 rounded-lg'
                            onClick={()=>handleAlta(5)}
                        >
                            <FaPlus className='mx-auto text-white'/>
                        </button>
                    </div>
                    <label htmlFor="sModelo" className="w-full block mt-5 text-black uppercase font-bold">Modelo</label>
                    <div className='w-full flex'>
                        <select 
                            className='py-2 px-3 w-11/12 rounded bg-slate-100' 
                            id='sModelo'
                            onChange={(e)=>setModelo(e.target.value)}
                        >
                            { modelos && (
                                modelos.map((item, index) => (
                                    <option key={index} value={item.id}>{item.descripcion}</option>))
                            ) }
                        </select>
                        <button 
                            type='button' 
                            className='w-1/12 bg-red-500 p2 rounded-lg'
                            onClick={()=>handleAlta(6)}
                        >
                            <FaPlus className='mx-auto text-white'/>
                        </button>
                    </div>
                </div>
                <div className="sm:items-start">
                    <label htmlFor="descripcion" className="w-full block mt-5 text-black uppercase font-bold">Descripción</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="descripcion" 
                        placeholder="Descripción" 
                        type="text" 
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    { formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="precio" className="block text-black uppercase font-bold">Precio</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="precio" 
                        placeholder="Ingrese el precio" 
                        type="number" 
                        value={formik.values.precio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.precio && formik.errors.precio ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.precio}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="stock" className="block text-black uppercase font-bold">Stock</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="stock" 
                        placeholder="Ingrese el stock actual" 
                        type="number" 
                        value={formik.values.stock}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.stock && formik.errors.stock ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.stock}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="stockMin" className="block text-black uppercase font-bold">Sotck Mínimo</label>
                    <input 
                        className="py-2 px-3 w-full rounded" 
                        id="stockMin" 
                        placeholder="Ingrese el stock mínimo" 
                        type="number" 
                        value={formik.values.stockMin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.stockMin && formik.errors.stockMin ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.stockMin}</p>
                        </div>
                    ) : null }
                </div>
            </div>
            <div className="pb-10">
                <div className="w-full rounded-lg px-3">
                    <input
                        type="submit"
                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                        value="Guardar Datos"
                    />
                </div>
            </div>
        </form>
)
}

export default FormSubModelo