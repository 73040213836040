import { Fragment, useRef, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ContextStates from '../context/ContextStates'
import FormNewOrder from './forms/FormNewOrder'
import FormQuit from './forms/FormQuit'
import FormLogin from './forms/FormLogin'
import FormMarca from './admin/formMarca'
import FormProducto from './admin/formProducto'
import FormModelo from './admin/formModelo'
import ViewPedido from './admin/viewPedido'
import FEditSubModelo from './admin/fEditSubModelo'
import FDelSubModelo from './admin/fDelSubModelo'
import { AiFillCloseCircle } from 'react-icons/ai'

const ModalView = () => {
    const cancelButtonRef = useRef(null)
    const { formView, viewOpen, setViewOpen } = useContext(ContextStates);
    


    return (
<>
    <Transition.Root show={viewOpen} as={Fragment}>
		<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setViewOpen}>
			<Transition.Child
				as={Fragment}
				enter="ease-out duration-300"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
			<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</Transition.Child>

			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:w-3/4 w-11/12">
							<div className="px-4 pt-2 sm:p-6">
								<button className='text-3xl' onClick={()=>setViewOpen(false)}><AiFillCloseCircle/></button>
								<div className="sm:flex sm:items-start">
									{formView===1 ? (
										<FormNewOrder/>
									):formView===2 ? (
										<FormQuit/>
									):formView===3 ? (
										<FormLogin/>
									):formView===4 ? (
										<FormMarca/>
									):formView===5 ? (
										<FormProducto/>
									):formView===6 ? (
										<FormModelo/>
									):formView===7 ? (
										<ViewPedido/>
									):formView===8 ? (
										<FEditSubModelo/>
									):formView===9 ? (
										<FDelSubModelo/>
									):null
									}
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</div>
		</Dialog>
		</Transition.Root>
    </>
    )
}

export default ModalView