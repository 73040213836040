import Layout from '../../components/admin/Layout';
import FormSubModelo from '../../components/admin/formSubModelo';

const AltaProductos = () => {


	return (
		<Layout
			pagina={"Producto"}
		>
			<div className='w-full p-5 min-h-full'>
				<FormSubModelo/>
			</div>
		</Layout>
	)
}

export default AltaProductos